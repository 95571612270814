import defaultService from './_default.service'

export default function saaim3Service() {
  const defaultMethods = defaultService('Auditorias')

  const fetchOperations = (data, callback) => {
    defaultMethods.fetch('SaaiM3', { }, data, callback)
  }
  const fetchImportFiles = (guidsArchivos, callback) => defaultMethods.create('SaaiM3/Importar', { }, true, guidsArchivos, callback)
  const fetchSXReferenciaNumber = (numeroReferencia, callback) => defaultMethods.fetch('SaaiM3/ImportarReferenciaSysExpert/%{numeroReferencia}', { numeroReferencia }, {}, callback)
  const fetchOperationAuditEvents = (inicioPedimentoId, data, callback) => defaultMethods.fetch('SaaiM3/%{inicioPedimentoId}/Hallazgos', { inicioPedimentoId }, data, callback)
  const fetchOperationLog = (inicioPedimentoId, callback) => defaultMethods.fetch('SaaiM3/%{inicioPedimentoId}/Bitacora', { inicioPedimentoId }, {}, callback)
  const createPaymentAuthorization = (inicioPedimentoId, data, callback) => defaultMethods.create('SaaiM3/AutorizarPago/%{inicioPedimentoId}', { inicioPedimentoId }, true, data, callback)
  const getAuthorizationRequest = (inicioPedimentoId, callback) => defaultMethods.fetch('SaaiM3/SolicitarAutorizacion/%{inicioPedimentoId}', { inicioPedimentoId }, {}, callback)

  return {
    fetchOperations,
    fetchImportFiles,
    fetchSXReferenciaNumber,
    fetchOperationAuditEvents,
    fetchOperationLog,
    createPaymentAuthorization,
    getAuthorizationRequest,
  }
}
