<template>
  <div>
    <b-form-file
      v-model="files"
      multiple
      :browse-text="$t('fileUploader.browseText')"
      :placeholder="$t('fileUploader.noFileSelected')"
      :state="state"
      :disabled="disabled"
      @input="onUploadFiles"
    />
    <div class="mt-1 ml-1 block">
      <ul>
        <li
          v-for="file in loadedFiles"
          :key="file.guid"
        >
          <div v-if="imageExtensionsAllowed.includes(file.extension)">
            <b-img
              :src="file.blob"
              width="150px"
            />
          </div>
          {{ file.nombre }}
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XCircleIcon"
            size="16"
            @click="onDeleteFile(file.nombre)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import fileService from '@/services/file.service'
import { ref } from '@vue/composition-api'
import common from '@/libs/common'

export default {
  components: {
    BFormFile,
    BImg,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    state: {
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
      default: 'standard',
    },
  },
  setup(props, { emit }) {
    /* Services */
    const { createFile } = fileService()
    // /* Data */
    const files = ref([])
    const loadedFiles = ref([])
    // /* Methods */

    const uploadFile = file => {
      createFile(file)
        .then(data => {
          loadedFiles.value.push({
            nombre: file.nombreArchivo,
            extension: file.nombreArchivo.split('.').pop(),
            guid: data,
            blob: file.blob,
          })
          files.value = files.value.filter(f => f.name !== file.nombreArchivo)

          emit('input', loadedFiles.value)
          emit('on-add-file')
        })
    }

    const onUploadFiles = () => {
      // importingFiles.value = true

      files.value.forEach(file => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () => {
          const byteArray = []

          if (file.name.endsWith('jpeg') || file.name.endsWith('jpg') || file.name.endsWith('png') || file.name.endsWith('PNG')) {
            const blob = new Blob([reader.result]) // create blob...
            window.URL = window.URL || window.webkitURL
            const blobURL = window.URL.createObjectURL(blob) // and get it's URL
            // helper Image object
            const image = new Image()
            image.src = blobURL
            image.onload = () => {
              // have to wait till it's loaded
              common().resizeImage(image).toBlob(blobResize => {
                // Convert blobl to array buffer
                blobResize.arrayBuffer()
                  .then(buff => {
                    const bytesRaw = new Uint8Array(buff)
                    // Convert arrayBuffer to simple array bytes
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of bytesRaw) {
                      byteArray.push(element)
                    }
                    uploadFile({ binarios: byteArray, nombreArchivo: file.name, blob: blobURL })
                  })
              })
            }
          } else {
            // Get array buffer, convert and post to server
            const arrayBuffer = reader.result
            const bytes = new Uint8Array(arrayBuffer)
            // eslint-disable-next-line no-restricted-syntax
            for (const element of bytes) {
              byteArray.push(element)
            }

            uploadFile({ binarios: byteArray, nombreArchivo: file.name })
          }
        }
      })
      emit('on-uploaded-files')
    }

    const onDeleteFile = fileName => {
      loadedFiles.value = loadedFiles.value.filter(f => f.nombre !== fileName)
      emit('input', loadedFiles.value)
      emit('on-delete-file')
    }

    const deleteFiles = () => {
      loadedFiles.value = []
      emit('input', loadedFiles.value)
    }

    const imageExtensionsAllowed = ref([
      'png',
      'PNG',
      'jpeg',
      'JPEG',
      'jpg',
      'JPG',
    ])

    return {
      // Data
      files,
      // importingFiles,
      loadedFiles,
      // Methods
      onUploadFiles,
      onDeleteFile,
      deleteFiles,
      imageExtensionsAllowed,
    }
  },
}
</script>

<style>

</style>
