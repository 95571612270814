<template>
  <div>
    <b-card>
      <b-card-header>
        {{ $t('AppAuditSaai.list.chargeFile.labelFile') }}
      </b-card-header>
      <b-card-body>
        <multiple-file-uploader
          ref="refFilesUploader"
          v-model="selectedFiles"
          @on-upload-file="onAddFiles"
        />
        <b-button
          class="block"
          :disabled="selectedFiles.length === 0 || isAuditingFiles"
          @click="onAuditFiles"
        >
          <b-spinner v-if="isAuditingFiles" />
          {{ $t('AppAuditSaai.list.chargeFile.btnChargeFile') }}
        </b-button>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header>
        {{ $t('AppAuditSaai.list.search.label') }}
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-input
              v-model="referenceNumber"
              class="d-inline-block mr-1"
              :placeholder="$t('AppAuditSaai.list.search.placeHolder')"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              class="d-inline-block mr-1"
              @click="onSearchReference"
            >
              {{ $t('AppAuditSaai.list.search.btnSearch') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <list
      ref="refOperationsList"
      sort-by="InicioPedimentoId"
      :sort-dir-desc="false"
      refetch-records-name="pedimentos"
      key-field="ParteId"
      report-name="CatalogoPartes.xlsx"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchOperations"
    >
      <template #cell(fechaAlta)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.fechaAlta | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.fechaAlta | formatDate }}
        </b-media>
      </template>
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveOperationStatusVariant(data.item)}`"
          class="text-capitalize"
        >
          {{ data.item.auditorOperaciones.length !== 0 ? data.item.auditorOperaciones[0].auditorOperacionEventos.length : 0 }} {{ $t('AppAudit.titlefinding')}}
        </b-badge>
      </template>
      <template #cell(freePayment)="data">
        <b-badge
          pill
          :variant="`light-${resolveOperationFreePaymentVariant(data.item)}`"
          class="text-capitalize"
        >
          {{ resolveOperationFreePaymentText(data.item) }}
        </b-badge>
      </template>
    </list>
    <b-modal
      id="modal-findings"
      v-model="showFindingsModal"
      :title="$t('AppAuditSaai.titlefinding')"
      size="xl"
    >
      <list
        ref="refOperationsFindingsList"
        sort-by="AuditorOperacionId"
        refetch-records-name="hallazgos"
        key-field="AuditorOperacionId"
        report-name="CatalogoPartes.xlsx"
        :actions="actionsOperationFindings"
        :filters.sync="filtersOperationFindings"
        :table-columns="tableOperationFindingsColumns"
        :table-actions="tableActionsOperationFindings"
        :refetch-records="fetchEvents"
      />
      <template #modal-footer>
        <span />
      </template>
    </b-modal>
    <b-modal
      id="modal-logs"
      v-model="showLogsModal"
      :title="$t('AppAuditSaai.titlelogbook')"
      size="xl"
    >
      <app-timeline>
        <app-timeline-item
          v-for="item in operationLogs"
          :key="item.inicioPedimentoBitacoraId"
          :variant="resolveVariantLog(item.evento)"
        >
          <div>
            {{ item.evento }}
          </div>
          <div>
            {{ item.observaciones }}
          </div>
          <div>
            {{ item.fechaHora }}
          </div>
          <div>
            {{ item.usuario }}
          </div>
        </app-timeline-item>
      </app-timeline>
      <template #modal-footer>
        <span />
      </template>
    </b-modal>
    <b-modal
      id="modal-authorization"
      v-model="showAuthorizationModal"
      :title="$t('AppAuditSaai.titleAuth')"
      size="xl"
    >
      <validation-observer
        ref="refAuthorizationRules"
        tag="form"
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppAuditSaai.typeAuth')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppAuditSaai.typeAuth')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="selectedOperationAudit.hallazgoAutorizacionTipoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="findingTypes"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppAuditSaai.observations')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppAuditSaai.observations')"
              >
                <b-form-textarea
                  v-model="selectedOperationAudit.observaciones"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <b-button
          @click="onPaymentAuthorization"
        >
          {{ $t('AppAuditSaai.authAction') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'

import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BFormInput,
  BModal,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInvalidFeedback,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'
import saaim3Service from '@/services/saaim3.service'
import List from '@/components/List.vue'
import MultipleFileUploader from '@/components/MultipleFileUploader.vue'
import i18n from '@/libs/i18n'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import { required } from '@validations'
import optionService from '@/services/option.service'
import common from '@/libs/common'

export default {
  components: {
    List,
    MultipleFileUploader,

    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BFormInput,
    BModal,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BSpinner,

    vSelect,

    AppTimeline,
    AppTimelineItem,

    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    /* Services */
    const {
      fetchOperations,
      fetchOperationAuditEvents,
      fetchImportFiles,
      fetchSXReferenciaNumber,
      fetchOperationLog,
      createPaymentAuthorization,
      getAuthorizationRequest,
    } = saaim3Service()

    const {
      fetchOptions,
    } = optionService()

    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateSaai')) {
      dateTimeFormater.push({ key: 'formatDateSaai', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateSaai'))

    let actions = ref([])

    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
      const index = actions.value.findIndex(e => e.name === 'dateAndHour')
      actions.value[index].value = stateTime.value.value
    }
    const { userIsInRole } = common()

    const refOperationsList = ref(null)
    const formatDateFilter = ref()
    const refConversation = ref(null)
    const refFilesUploader = ref(null)
    const selectedFiles = ref([])
    const referenceNumber = ref('')
    const selectedFileNames = ref([])
    const selectedOperationId = ref(null)
    const refAuthorizationRules = ref(null)
    const selectedOperationAudit = ref({
      hallazgoAutorizacionTipoId: null,
      observaciones: '',
    })

    const showFindingsModal = ref(false)
    const showLogsModal = ref(false)
    const showAuthorizationModal = ref(false)
    const isAuditingFiles = ref(false)

    const operationLogs = ref([])

    const findingTypes = ref([])
    fetchOptions({ nombreDominio: 'HallazgoAutorizacionTipo' }, data => {
      findingTypes.value = data
    })

    const filters = ref([
      {
        type: 'select',
        name: 'clienteId',
        label: i18n.t('AppAuditSaai.list.filters.client'),
        value: '',
        options: [],
        domainName: 'Clientes',
      },
    ])

    const filtersOperationFindings = ref([])

    const tableColumns = [
      {
        key: 'fechaAlta',
        label: i18n.t('AppAuditSaai.list.columns.date'),
        sortable: true,
        sortkey: 'FechaAlta',
      },
      {
        key: 'generales[0].nombreImportadorExportador',
        label: i18n.t('AppAuditSaai.list.columns.client'),
        sortable: true,
        sortkey: 'FechaAlta',
      },
      {
        key: 'patente',
        label: i18n.t('AppAuditSaai.list.columns.patent'),
        sortable: true,
        sortkey: 'Patente',
      },
      {
        key: 'aduanaDespacho',
        label: i18n.t('AppAuditSaai.list.columns.custom'),
        sortable: true,
        sortkey: 'AduanaDespacho',
      },
      {
        key: 'numeroPedimento',
        label: i18n.t('AppAuditSaai.list.columns.request'),
        sortable: true,
        sortkey: 'NumeroPedimento',
      },
      {
        key: 'generales[0].clavePedimento',
        label: i18n.t('AppAuditSaai.list.columns.documentKey'),
        sortable: true,
        sortkey: 'NumeroPedimento',
      },
      {
        key: 'status',
        label: i18n.t('AppAuditSaai.list.columns.finding'),
        sortable: false,
        sortkey: 'NumeroPedimento',
      },
      {
        key: 'freePayment',
        label: i18n.t('AppAuditSaai.list.columns.status'),
        sortable: false,
        sortkey: 'NumeroPedimento',
      },
      {
        label: i18n.t('AppAuditSaai.list.columns.actions'),
        key: 'actions',
        sortable: false,
      },
    ]

    actions = ref([
      {
        name: 'dateAndHour',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        icon: 'CalendarIcon',
        value: stateTime.value.value,
        click: changeStateTime,
      },
    ])

    const tableOperationFindingsColumns = [
      {
        key: 'auditorOperacionEventoId',
        label: i18n.t('AppAuditSaai.list.columns.invoice'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
      {
        key: 'auditoriaEventoTipo.nombre',
        label: i18n.t('AppAuditSaai.list.columns.finding'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
      {
        key: 'nivelInformacion',
        label: i18n.t('AppAuditSaai.list.columns.lvlinfo'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
      {
        key: 'campo',
        label: i18n.t('AppAuditSaai.list.columns.field'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
      {
        key: 'campoValorTiene',
        label: i18n.t('AppAuditSaai.list.columns.valHas'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
      {
        key: 'campoValorDebeTener',
        label: i18n.t('AppAuditSaai.list.columns.valMustHave'),
        sortable: true,
        sortkey: 'AuditorOperacionEventoId',
      },
    ]
    const actionsOperationFindings = ref([])

    const onAddFiles = () => {
      fetchImportFiles(selectedFiles.value, () => {
        selectedFiles.value = []
        refOperationsList.value.refreshData()
      })
    }

    const onAuditFiles = () => {
      isAuditingFiles.value = true
      fetchImportFiles(selectedFiles.value, () => {
        refFilesUploader.value.deleteFiles()
        refOperationsList.value.refreshData()
        isAuditingFiles.value = false
      })
    }

    // eslint-disable-next-line arrow-body-style
    const resolveOperationStatusVariant = item => {
      if (item.auditorOperaciones.length === 0) return 'success'
      if (item.auditorOperaciones[0].auditorOperacionEventos.length > 0) return 'warning'
      return 'success'
    }

    const resolveOperationFreePaymentVariant = item => {
      if (item.auditorOperaciones.length === 0 || item.auditorOperaciones[0].fechaHoraAutorizacionPago) return 'success'
      if (item.auditorOperaciones[0].fechaHoraSolicitudAutorizacion) return 'warning'
      return 'warning'
    }

    const onShowFindingsModal = item => {
      selectedOperationId.value = item.inicioPedimentoId
      showFindingsModal.value = true
    }
    const onShowLogsModal = item => {
      showLogsModal.value = true
      fetchOperationLog(item.inicioPedimentoId, data => {
        operationLogs.value = data
      })
    }
    const onShowAuthorizationModal = item => {
      selectedOperationId.value = item.inicioPedimentoId
      // eslint-disable-next-line prefer-destructuring
      selectedOperationAudit.value = item.auditorOperaciones[0]
      showAuthorizationModal.value = true
    }
    const onRequestAuthorization = item => {
      getAuthorizationRequest(item.inicioPedimentoId, () => {
        refOperationsList.value.refreshData()
      })
    }

    const tableActions = ref([
      {
        name: 'findings',
        label: i18n.t('AppAuditSaai.list.actionList.viewFindings'),
        aclAction: 'read',
        aclResource: 'AuditoriaMolex',
        click: onShowFindingsModal,
        disabledCondition: item => item.auditorOperaciones.length === 0 || item.auditorOperaciones[0].auditorOperacionEventos.length === 0,
        icon: 'SearchIcon',
      },
      {
        name: 'logs',
        label: i18n.t('AppAuditSaai.list.actionList.viewLogbook'),
        aclAction: 'read',
        aclResource: 'AuditoriaMolex',
        click: onShowLogsModal,
        icon: 'BookOpenIcon',
      },
      {
        name: 'authorizationRequest',
        label: i18n.t('AppAuditSaai.list.actionList.requestAuth'),
        aclAction: 'read',
        aclResource: 'AuditoriaMolex',
        click: onRequestAuthorization,
        disabledCondition: item => item.auditorOperaciones.length === 0 || item.auditorOperaciones[0].fechaHoraSolicitudAutorizacion !== undefined || item.auditorOperaciones[0].fechaHoraAutorizacionPago !== undefined,
        icon: 'UserCheckIcon',
      },
      {
        name: 'authorization',
        label: i18n.t('AppAuditSaai.list.actionList.authorization'),
        aclAction: 'read',
        aclResource: 'AuditoriaMolex',
        click: onShowAuthorizationModal,
        disabledCondition: item => item.auditorOperaciones.length === 0 || item.auditorOperaciones[0].fechaHoraAutorizacionPago !== undefined || !(userIsInRole('GerenteOperativo') || userIsInRole('JefeOperativo') || userIsInRole('Administrator')),
        icon: 'CheckCircleIcon',
      },
    ])
    const tableActionsOperationFindings = ref([])
    const fetchEvents = (data, callback) => {
      fetchOperationAuditEvents(selectedOperationId.value, data, callback)
    }

    const resolveVariantLog = event => {
      if (event === 'ALTA OPERACION') return 'primary'
      if (event === 'AUDITORIA APLICADA') return 'warning'
      if (event === 'ACTUALIZACION OPERACION') return 'info'
      if (event === 'AUTORIZADO A PAGO') return 'secondary'
      if (event === 'SOLICITUD AUTORIZACION PAGO') return 'secondary'
      return ''
    }

    const resolveOperationFreePaymentText = item => {
      if (item.auditorOperaciones.length === 0) return i18n.t('AppAuditSaai.list.columns.pending')
      if (item.auditorOperaciones[0].fechaHoraAutorizacionPago) return i18n.t('AppAuditSaai.list.columns.authorize')
      if (item.auditorOperaciones[0].fechaHoraSolicitudAutorizacion) return i18n.t('AppAuditSaai.list.columns.reqAuth')

      return i18n.t('AppAuditSaai.list.columns.pending')
    }

    const onSearchReference = () => {
      fetchSXReferenciaNumber(referenceNumber.value, () => {
        referenceNumber.value = ''
        refOperationsList.value.refreshData()
      })
    }

    const onPaymentAuthorization = () => {
      refAuthorizationRules.value.validate().then(success => {
        if (success) {
          createPaymentAuthorization(selectedOperationId.value, selectedOperationAudit.value, () => {
            showAuthorizationModal.value = false
            refOperationsList.value.refreshData()
          })
        }
      })
    }

    const {
      getValidationState,
    } = formValidation()

    return {
      refOperationsList,
      refConversation,
      refFilesUploader,
      refAuthorizationRules,
      selectedFiles,
      referenceNumber,
      selectedFileNames,
      showFindingsModal,
      showLogsModal,
      showAuthorizationModal,
      isAuditingFiles,
      filters,
      filtersOperationFindings,
      tableColumns,
      tableOperationFindingsColumns,
      tableActions,
      tableActionsOperationFindings,
      actions,
      actionsOperationFindings,
      stateTime,

      onPaymentAuthorization,

      operationLogs,
      findingTypes,
      selectedOperationAudit,

      formatDateFilter,
      fetchOperations,
      fetchEvents,
      onAddFiles,
      onSearchReference,
      onAuditFiles,
      resolveOperationStatusVariant,
      resolveOperationFreePaymentVariant,
      resolveOperationFreePaymentText,
      resolveVariantLog,

      getValidationState,
      required,
    }
  },
}
</script>

<style>

</style>
